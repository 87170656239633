import React, { useState, useEffect } from 'react';
import {
    onSnapshot,
    collection,
    query
} from 'firebase/firestore';
import db from '../firebase_setup/firebase';

function RandomMonument() {
    const collectionRef = collection(db, 'monumentjes');

    const [monumentjes, setMonumentjes] = useState([]);
    const [loading, setLoading] = useState(false);

    //REALTIME GET FUNCTION
    useEffect(() => {
        setLoading(true);
        // const unsub = onSnapshot(q, (querySnapshot) => {
        const unsub = onSnapshot(collectionRef, (querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            const randomMonumentje = items.sort(() => Math.random() - Math.random())
                .find(() => true);
            const randomItem = [];
            randomItem.push(randomMonumentje);
            setMonumentjes(randomItem);
            setLoading(false);
        });
        return () => {
            unsub();
        };

        // eslint-disable-next-line
    }, []);

    function showDate(timestamp) {
        let date = timestamp.toDate()
        let mm = date.getMonth() + 1;
        let dd = date.getDate();
        let yyyy = date.getFullYear();
        let hh = date.getHours();
        let min = date.getMinutes();
        let sec = date.getSeconds();

        date = dd + '/' + mm + '/' + yyyy + " " + hh + ":" + min + ":" + sec;
        return date;
    }

    return (
        <ul>
            {loading ? <h1>Monumentjes laden...</h1> : null}
            {monumentjes.map((monument) => (
                <li key={monument.id} className="py-4 flex">
                    <img className="h-10 w-10 rounded-full" src={monument.image} alt="" />
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">{monument.monumentName}</p>
                        <p className="text-sm text-gray-500">{monument.monumentPlace} - {showDate(monument.monumentDate)}</p>
                        <p className="text-sm font-medium text-gray-900">"{monument.monumentText}"</p>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default RandomMonument;