import React, {useState} from "react";
import {
    collection,
    doc,
    setDoc
} from 'firebase/firestore';
import db from '../firebase_setup/firebase';
import { v4 as uuidv4 } from 'uuid';

function CreateMonument() {

    const collectionRef = collection(db, 'monumentjes');

    const [monumentDate, setMonumentDate] = useState(new Date());
    const [monumentDatePicker , setMonumentDatePicker] = useState('');
    const [monumentName, setMonumentName] = useState('');
    const [monumentPlace, setMonumentPlace] = useState('');
    const [monumentText, setMonumentText] = useState('');

    async function addMonument(event) {
        // event.preventDefault();
        const newMonument = {
            id: uuidv4(),
            monumentDate,
            monumentName,
            monumentPlace,
            monumentText
        };

        try {
            // const newMonumentDate = new Date(monumentDatePicker);
            // alert(newMonumentDate);
            setMonumentDate(new Date()); // The new Date does not yet translate the datepicker's date to a firestore readable date. It will always select the current datetime.
            console.log(newMonument)
            const monumentRef = doc(collectionRef, newMonument.id);
            setDoc(monumentRef, newMonument);
            alert("Monumentje opgeslagen!");
            setMonumentName('');
            setMonumentPlace('');
            setMonumentText('');
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <form>
             <div className="space-y-12">
                 <div className="border-b border-gray-900/10 pb-12">
                     <h2 className="text-base font-semibold leading-7 text-gray-900">Monumentje Invoeren</h2>
                     <p className="mt-1 text-sm leading-6 text-gray-600">
                         Vul hier je monumentje in!
                     </p>

                     <div className="sm:col-span-3">
                         <label htmlFor="member-name" className="block text-sm font-medium leading-6 text-gray-900">
                             Naam
                         </label>
                         <div className="mt-2">
                             <select
                                id="member-name"
                                name="member-name"
                                value={monumentName}
                                onChange={(e) => setMonumentName(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                                <option></option>
                                <option value='Anne'>Anne</option>
                                <option value='Annekee'>Annekee</option>
                                <option value='Bas'>Bas</option>
                                <option value='Kevin'>Kevin</option>
                                <option value='Marc'>Marc</option>
                                <option value='Nynke'>Nynke</option>
                                <option value='Sander'>Sander</option>
                                <option value='Victoria'>Victoria</option>
                            </select>
                        </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="place" className="block text-sm font-medium leading-6 text-gray-900">
                                Plaats
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="place"
                                    id="place"
                                    value={monumentPlace}
                                    onChange={(e) => setMonumentPlace(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/*<div className="sm:col-span-3">*/}
                        {/*    <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">*/}
                        {/*        Datum*/}
                        {/*    </label>*/}
                        {/*    <div className="mt-2">*/}
                        {/*        <input*/}
                        {/*            type="date"*/}
                        {/*            name="date"*/}
                        {/*            id="date"*/}
                        {/*            value={monumentDatePicker}*/}
                        {/*            onChange={(e) => setMonumentDatePicker(e.target.value)}*/}
                        {/*            className="custom-input-date custom-input-date-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="col-span-full">
                            <label htmlFor="monument" className="block text-sm font-medium leading-6 text-gray-900">
                                Monument
                            </label>
                            <div className="mt-2">
                                <textarea
                                    id="monument"
                                    name="monument"
                                    rows={3}
                                    value={monumentText}
                                    onChange={(e) => setMonumentText(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={() => addMonument()}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>
        </form>
    )
}

export default CreateMonument;