import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAhkf8bkz6XV5ADlsj9btvvEVbqJNwPUyU",
    authDomain: "vreetclub-e4ab1.firebaseapp.com",
    projectId: "vreetclub-e4ab1",
    storageBucket: "vreetclub-e4ab1.appspot.com",
    messagingSenderId: "927418866232",
    appId: "1:927418866232:web:74afef0e811fdec4dce4cd"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();

export default getFirestore(app);