// import './App.css';
// import handleSubmit from './handles/handlesubmit';
// import { useRef } from 'react';
//
// function App() {
//     const dataRef = useRef()
//
//     const submithandler = (e) => {
//         e.preventDefault()
//         handleSubmit(dataRef.current.value)
//         dataRef.current.value = ""
//     }
//
//     return (
//         <div className="App">
//             <form onSubmit={submithandler}>
//                 <input type= "text" ref={dataRef} />
//                 <button type = "submit">Save</button>
//             </form>
//         </div>
//     );
// }
//
// export default App;

import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';

import './App.css';

import HeaderVreetclub from './components/header';
import CreateMonument from './components/createMonument';
import AllMonuments from "./components/allMonuments";
import RandomMonument from "./components/randomMonument";

function App() {
  return (
    <div className="App">
        <Router>
        <HeaderVreetclub/>
            <Routes>
                <Route path="/" element={<RandomMonument />} />
                <Route path="/monumentje-invoeren" element={<CreateMonument />} />
                <Route path="/alle-monumentjes" element={<AllMonuments />} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;