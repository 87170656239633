import React, { useState, useEffect } from 'react';
import {
    onSnapshot,
    collection,
    query
} from 'firebase/firestore';
import db from '../firebase_setup/firebase';

// const allMonuments = [
//     {
//         id: 0,
//         name: 'Nynke',
//         date: '31-07-2020',
//         place: 'Zoeterwoude',
//         text: 'Dos servecas por favor, dat betekent toch twee servetten alsjeblieft?',
//         image:
//             'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//     {
//         id: 1,
//         name: 'Nynke',
//         date: '31-05-2020',
//         place: 'Zoeterwoude',
//         text: 'Dos servecas por favor, dat betekent toch twee servetten alsjeblieft?',
//         image:
//             'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//     {
//         id: 2,
//         name: 'Nynke',
//         date: '31-01-2020',
//         place: 'Zoeterwoude',
//         text: 'Dos servecas por favor, dat betekent toch twee servetten alsjeblieft?',
//         image:
//             'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
// ]

function AllMonuments() {
    const collectionRef = collection(db, 'monumentjes');

    const [monumentjes, setMonumentjes] = useState([]);
    const [loading, setLoading] = useState(false);

    //REALTIME GET FUNCTION
    useEffect(() => {
        const q = query(
            collectionRef
        );

        setLoading(true);
        // const unsub = onSnapshot(q, (querySnapshot) => {
        const unsub = onSnapshot(q, (querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setMonumentjes(items);
            setLoading(false);
        });
        return () => {
            unsub();
        };

        // eslint-disable-next-line
    }, []);

    function showDate(timestamp) {
        let date = timestamp.toDate()
        let mm = date.getMonth() + 1;
        let dd = date.getDate();
        let yyyy = date.getFullYear();
        let hh = date.getHours();
        let min = date.getMinutes();
        let sec = date.getSeconds();

        date = dd + '/' + mm + '/' + yyyy + " " + hh + ":" + min + ":" + sec;
        return date;
    }

    return (
        <ul>
            {loading ? <h1>Monumentjes laden...</h1> : null}
            {monumentjes.map((monument) => (
                <li key={monument.id} className="py-4 flex">
                    <img className="h-10 w-10 rounded-full" src={monument.image} alt="" />
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">{monument.monumentName}</p>
                        <p className="text-sm text-gray-500">{monument.monumentPlace} - {showDate(monument.monumentDate)}</p>
                        <p className="text-sm font-medium text-gray-900">"{monument.monumentText}"</p>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default AllMonuments;